// src/reducers/userReducer.js
const initialState = {
    loading: false,
    error: null,
    email: null
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'USER_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'USER_SUCCESS':
            return {
                ...state,
                loading: false,
            };
        case 'USER_ME_SUCCESS':
            return {
                ...state,
                loading: false,
                email: action.payload.email,
            };
        case 'USER_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
