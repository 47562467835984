import React from 'react';
import '../styles/modaldelete.css'; // Asegúrate de que los estilos del modal estén bien configurados

export const ModalDelete = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p className='modal-p'>{message}</p>
                <div className="modal-buttons">
                    <button className="modal-button confirm-button" onClick={onConfirm}>Sí</button>
                    <button className="modal-button cancel-button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};
