import React from 'react';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Importa hooks de Redux
import { logout } from '../actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/header.css';

export const Header = ({ showCreateFieldButton = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogOut = () => {
        dispatch(logout(navigate));
    };

    const handleCreateField = () => {
        navigate('/create/field/qr');
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='logo-div'>
            <img src={nematool_logo_RGB} alt="Bayer Logo" className="header-logo" />
            <div className='boton-menu-div'>
                <FontAwesomeIcon className='svg-settings' icon={faGear} />
                    
                {showCreateFieldButton && (
                    <button 
                        className='header-button create-field-button'
                        onClick={handleCreateField}
                    >
                        Create field
                    </button>
                )}
                {!showCreateFieldButton && (
                    <button 
                        className='header-button create-field-button'
                        onClick={handleGoBack}
                    >
                        Go back
                    </button>
                )}
                <button 
                    className='header-button log-out-button'
                    onClick={handleLogOut}
                >
                    Log out
                </button>
            </div>
        </div>
    );
};
