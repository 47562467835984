import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa el hook useNavigate
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';

import '../styles/fieldCard.css';

export const FieldCard = ({ fieldId, fieldName, sensorNumber, activeSensor, signalSensor, model, ownerEmail, userEmailLogin, expirationLicenseDate }) => {
    const navigate = useNavigate(); // Inicializa el hook useNavigate
    const hasSensor = sensorNumber !== 'No sensor';

    const licenceStatus = avalibleLicence(expirationLicenseDate);
    
    // Maneja el evento de clic en el componente
    const handleClick = () => {
        if (licenceStatus) {
            navigate(`/field/${fieldId}`);
        }
    };

    const handleClickBuyLicense = () => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    return (
        <div 
            className={`sensor-card ${!licenceStatus ? 'inactive-card' : ''}`} 
            onClick={handleClick} 
            style={{ backgroundColor: !licenceStatus ? 'rgba(128, 128, 128, 0.4)' : 'white' }}
        > 
            {!licenceStatus && 
            <div className="expired-license">
                {/* <i className="fa fa-exclamation-triangle"></i> */}
                {userEmailLogin === ownerEmail && hasSensor && (
                    <div className='expired-license-container'>
                        {!expirationLicenseDate ? (
                            <p id='expired-license-text'>No license</p>
                        ) : (
                            <p id='expired-license-text'>The license expired {expirationDays(expirationLicenseDate)} days ago</p>
                        )}
                        <button className="license-button" onClick={handleClickBuyLicense}>
                            Buy license
                        </button>
                    </div>
                )}
                {userEmailLogin === ownerEmail && !hasSensor && (
                    <div className='expired-license-container'>
                        <button className="link-button" onClick={handleClickLinkSensor}>
                            Link sensor
                        </button>
                    </div>
                )}
            </div>}
            <div className="sensor-card-header">
                <div className="sensor-status">
                    <span className={`status-indicator ${activeSensor}`}></span>
                    <span>{activeSensor} sensor</span>
                </div>
                <div className="signal-status">
                    {signalSensor} <i className="fa fa-signal"></i>
                </div>
            </div>

            <div className="sensor-card-body">
                <h3>{fieldName}</h3>
                <p className="plot-id">#{sensorNumber}</p>
                {licenceStatus &&(
                    <p className="plot-id">#{formatDateLicence(expirationLicenseDate)}</p>
                )}
                <p className="plot-id">#{model[0]}</p>
                
                {model[0] === 'Nematode active' && licenceStatus && (
                    <div>
                        <div className="progress-section">
                            <div className="progress-item">
                                <div className="label-row">
                                    <span className="label label-model">Egg</span>
                                    <span className="generation">{model[1]}th generation</span>
                                    <span className="label">{model[2]}%</span>
                                </div>
                                <div className={`progress-bar ${model[3]}`}>
                                    <div className="progress" style={{ width: `${model[2]}%`, height: '13px', alignContent: 'center' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="progress-section">
                            <div className="progress-item">
                                <div className="label-row">
                                    <span className="label label-model">Larvicide</span>
                                    <span className="generation">{model[4]}th generation</span>
                                    <span className="label">{model[5]}%</span>
                                </div>
                                <div className={`progress-bar ${model[6]}`}>
                                    <div className="progress" style={{ width: `${model[5]}%`, height: '13px', alignContent: 'center' }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {model[0] === 'Solarization active' && licenceStatus && (
                    <div className="progress-section">
                        <div className="progress-item">
                            <div className="label-row">
                                <span className="label label-model">Degrees</span>
                                <span className="label">{model[1]}ºC</span>
                            </div>
                            <div className={`progress-bar orange`}>
                                <div className="progress" style={{ width: `${model[2]}%`, height: '13px', alignContent: 'center' }}></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="sensor-card-footer">
                <span className="shared-by">Owner: {ownerEmail}</span>
            </div>
        </div>
    );
};
