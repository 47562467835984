import React from 'react';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Importa hooks de Redux
import { logout } from '../actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faBell } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/header2.css';

export const Header2 = ({ showCreateFieldButton = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSettings = () => {
        navigate('/settings');
    };

    const handleLogOut = () => {
        dispatch(logout(navigate));
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <div className='logo-div'>
            <FontAwesomeIcon className='svg-settings' icon={faGear} onClick={handleSettings} />
            <img src={nematool_logo_RGB} alt="Bayer Logo" className="header-logo" />
            <FontAwesomeIcon className='svg-settings' icon={faBell} />
        </div>
    );
};
