// src/store.js
import { createStore, applyMiddleware, compose  } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers'; // Lo crearemos en el siguiente paso

const initialState = {};

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
