import React, { useState } from 'react';

export const DateNavigator = ({ onDateChange }) => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const formatDate = (date) => {
        return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const handlePreviousDay = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 1);
        setCurrentDate(newDate);
        onDateChange(newDate);
    };

    const handleNextDay = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 1);
        setCurrentDate(newDate);
        onDateChange(newDate);
    };

    return (
        <div className='div-date-navigator' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <button onClick={handlePreviousDay} className='date-button' style={{ marginRight: '20px' }}>⬅️</button>
            <span>{formatDate(currentDate)}</span>
            {currentDate < today && (
                <button onClick={handleNextDay} className='date-button' style={{ marginLeft: '20px' }}>➡️</button>
            )}
        </div>
    );
};
