import React, { useEffect, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFields } from '../actions/fieldsActions';
import { FieldCard } from './FieldCard';
import { getSignalStatus, getModel, getOwner } from '../utils/fieldUtils';
import '../styles/fields.css';
import { Header } from './Header';
import { Header2 } from './Header2';
import { messaging } from '../firebase';
import { getToken } from 'firebase/messaging';
import { processGCM } from '../actions/userActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom';
import { getUser } from '../actions/userActions';

export const Fields = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { fields = [], loading, error } = useSelector((state) => state.fields);
    const { email: userEmail} = useSelector((state) => state.user);
    const fieldListRef = useRef(null);

    useEffect(() => {
        dispatch(getUser());
        const requestPermissionAndRegisterSW = async () => {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                    const token = await getToken(messaging, {
                        vapidKey: 'BHARREGztf47ESSTmbIR8yMW2qF2ZSVnuZNqrX2HplkKWO1ndN7HoDQQyr1MsZextr8A9dTZQDVnFqf1IvBwYPw',
                        serviceWorkerRegistration: registration,
                    });
                    if (token) {
                        await processGCM(token);
                    } else {
                        console.log('No registration token available.');
                    }
                }
            } catch (error) {
                console.error('Error getting FCM token:', error);
            }
        };

        requestPermissionAndRegisterSW();
    }, []);

    useEffect(() => {
        if (fieldListRef.current) {
            fieldListRef.current.innerHTML = '';
        }

        dispatch(getFields());

    }, [dispatch]);

    const handleCreateField = () => {
        navigate('/create/field/qr');
    };

    return (
        <div className="field-container">
            <Header2 showCreateFieldButton={true} />
            {loading && (
                <div className="load-3">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            )}
            {error && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className='addField-div' onClick={handleCreateField}>
                <FontAwesomeIcon className='svg-settings-add' icon={faPlus} />
                <p className='addField-p'>Añadir campo</p>
            </div>
            <div className='fieldList' ref={fieldListRef}>
                {fields.map((f) => (
                    <FieldCard 
                        key={f.id}
                        fieldId={f.id}
                        fieldName={f.name} 
                        sensorNumber={f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'No sensor'}   
                        activeSensor={f.sensors.length > 0 && f.sensors[0].is_activated ? 'Active' : 'Desactive'}
                        signalSensor={f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'No Signal'}
                        model={getModel(f)}
                        ownerEmail={getOwner(f)}
                        userEmailLogin={userEmail}
                        expirationLicenseDate={f.expiration_license_date}
                    />
                ))}
            </div>
        </div>
    );
};
