import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { register } from '../actions/userActions';
import bayer_logo from '../assets/bayer-logo.svg';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/login.css';
import '../styles/register.css';

export const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const { loading } = useSelector((state) => state.user);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setPasswordError('');

        if (email === ''){
            setPasswordError('Complete the email.');
            return;
        }

        if (password === ''){
            setPasswordError('Complete the password.');
            return;
        }

        if (password !== repeatPassword) {
            setPasswordError('Passwords do not match.');
            return;
        }
        
        const response = await register(email, password, isCheckboxChecked, navigate);
        if (typeof response === 'string') {
            setPasswordError(response);
        } else {
            navigate('/login');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowRepeatPassword = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    return (
        <div className='login-body-container'>
            <div className="login-logo-container">
                <img src={bayer_logo} alt="Bayer Logo" className="login-logo" />
            </div>
            <div className="login-container">
                <div className="login-logo-container login-logo-nematool">
                    <img src={nematool_logo_RGB} alt="Nematool Logo" className="login-svg_nematool" />
                </div>
                {passwordError && <p style={{ color: 'red' }}><strong>{passwordError}</strong></p>}
                <form>
                    <div className="login-form-group">
                        <label htmlFor="email">Write your e-mail address:</label>
                        <input
                            className='login-input'
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="password">Choose a password:</label>
                        <input
                            className='login-input'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span onClick={toggleShowPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="repeatPassword">Confirm  your password</label>
                        <input
                            className='login-input'
                            type={showRepeatPassword ? "text" : "password"}
                            id="repeatPassword"
                            name="repeatPassword"
                            placeholder="Repeat your password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                        />
                        <span onClick={toggleShowRepeatPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showRepeatPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className="login-form-group checkbox-container">
                        <input
                            type="checkbox"
                            id="lorem-checkbox"
                            name="lorem-checkbox"
                            className="custom-checkbox"
                            checked={isCheckboxChecked}
                            onChange={(e) => setIsCheckboxChecked(e.target.checked)} // Actualizar el estado del checkbox
                        />
                        <label id='label-checkbox' htmlFor="lorem-checkbox">
                            Yes, I wish to receive commercial information from Bayer Cropscience, S.L.
                        </label>
                    </div>
                    <div className='div-login-button'>
                        <button className='login-button' onClick={handleSubmit} disabled={loading}>
                            {loading ? 'Cargando...' : 'Register'}
                        </button>
                    </div>
                    <div id='div-login-register'>
                        <a>Do you already have an account? <a id='login-click-here' href="/login">Log in</a></a>
                    </div>
                </form>
                <div className="login-footer">
                    <a href="https://nematool.com/Nematool-UserLicenseAgreement.pdf" id='terminos'>Terms and conditions</a>
                    <a href="https://nematool.com/Nematool-DataPrivacyStatement.pdf">Privacy policy</a>
                </div>
            </div>
            <div className="login-copy">
                &copy; Bayer CropScience S.L.
            </div>
        </div>
    );
};
