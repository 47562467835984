import { getField } from '../actions/fieldsActions';
const apiUrl = process.env.REACT_APP_API_URL;

export const postNematode = (fieldId) => async (dispatch) => {
    dispatch({ type: 'MODEL_REQUEST' });

    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/prediction_models/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ field: fieldId }), // Asegúrate de enviar el ID del campo
        });

        if (response.ok) {
            const data = await response.json();

            dispatch({ type: 'MODEL_SUCCESS', payload: data });

            dispatch(getField(fieldId));
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                dispatch({ type: 'MODEL_FAILURE', payload: "Error: Credenciales incorrectas" });
            } else {
                dispatch({ type: 'MODEL_FAILURE', payload: errorData.error || "Error desconocido" });
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        dispatch({ type: 'MODEL_FAILURE', payload: error.message });
    }
};

export const postSolarization = (fieldId) => async (dispatch) => {
    dispatch({ type: 'MODEL_REQUEST' });

    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/solarizations/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ field: fieldId }), // Asegúrate de enviar el ID del campo
        });

        if (response.ok) {
            const data = await response.json();

            dispatch({ type: 'MODEL_SUCCESS', payload: data });

            dispatch(getField(fieldId));
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                dispatch({ type: 'MODEL_FAILURE', payload: "Error: Credenciales incorrectas" });
            } else {
                dispatch({ type: 'MODEL_FAILURE', payload: errorData.error || "Error desconocido" });
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        dispatch({ type: 'MODEL_FAILURE', payload: error.message });
    }
};

export const putNematode = (fieldId, nematodeId) => async (dispatch) => {
    dispatch({ type: 'MODEL_REQUEST' });

    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/prediction_models/${nematodeId}/stop/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            dispatch({ type: 'MODEL_SUCCESS', payload: data });

            dispatch(getField(fieldId));
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                dispatch({ type: 'MODEL_FAILURE', payload: "Error: Credenciales incorrectas" });
            } else {
                dispatch({ type: 'MODEL_FAILURE', payload: errorData.error || "Error desconocido" });
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        dispatch({ type: 'MODEL_FAILURE', payload: error.message });
    }
};

export const putSolarization = (fieldId, solarizationId) => async (dispatch) => {
    dispatch({ type: 'MODEL_REQUEST' });

    try {
        const localtoken = localStorage.getItem('authToken'); // Asegúrate de obtener el token aquí
        const response = await fetch(`${apiUrl}/api/v1/nematool/solarizations/${solarizationId}/stop/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            dispatch({ type: 'MODEL_SUCCESS', payload: data });

            dispatch(getField(fieldId));
        } else {
            const errorData = await response.json();

            if (response.status === 401) {
                dispatch({ type: 'MODEL_FAILURE', payload: "Error: Credenciales incorrectas" });
            } else {
                dispatch({ type: 'MODEL_FAILURE', payload: errorData.error || "Error desconocido" });
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);

        dispatch({ type: 'MODEL_FAILURE', payload: error.message });
    }
};

