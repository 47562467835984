const initialState = {
    fields: [],
    fieldData: null,
    loading: false,
    error: null,
};

export default function fieldsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FIELDS_REQUEST':
            return {
                ...state,
                loading: true,
                error: null,
            };
        case 'FIELDS_SUCCESS':
            // Diferenciamos si es un campo o un conjunto de campos
            if (Array.isArray(action.payload)) {
                return {
                    ...state,
                    loading: false,
                    fields: action.payload,
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    fieldData: action.payload,
                };
            }
        case 'FIELDS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
