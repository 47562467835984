const apiUrl = process.env.REACT_APP_API_URL;

export const getTemperatureData = async (fieldId, timestamp_min, timestamp_max) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const params = new URLSearchParams({
            field: fieldId,
            key: 'temperature-underground',
            timestamp_min: timestamp_min,
            timestamp_max: timestamp_max
        }).toString();
        
        const response = await fetch(`${apiUrl}/api/v1/sensor_key_values/time_series/?${params}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data

        } else {
            console.log("Error")
        }
    } catch (error) {
        console.log("Error")
    }
};