import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { RecoverPassword } from './components/RecoverPassword';
import { Fields } from './components/Fields';
import { Field } from './components/Field';
import { Settings } from './components/Settings';
import { QRScan } from './components/QRScan';
import { FormField } from './components/FormField';
import PrivateRoute from './components/PrivateRoute';
import { AddLicense } from './components/AddLicense';
import { LinkSensor } from './components/LinkSensor';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/recoverPassword" element={<RecoverPassword />} />
        <Route path="/fields" element={<PrivateRoute element={Fields} />} />
        <Route path="/settings" element={<PrivateRoute element={Settings} />} />
        <Route path="/field/:fieldId" element={<PrivateRoute element={Field} />} />
        <Route path="/create/field/qr" element={<PrivateRoute element={QRScan} />} />
        <Route path="/create/field/license" element={<PrivateRoute element={AddLicense} />} />
        <Route path="/create/field/form" element={<PrivateRoute element={FormField} />} />
        <Route path="/link/sensor" element={<PrivateRoute element={LinkSensor} />} />
      </Routes>
    </Router>
  );
}

export default App;
