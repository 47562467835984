import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { getTemperatureData } from '../actions/temperatureActions';

export const LineChart = ({ selectedDate, fieldId }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);

    // Función para formatear la fecha a YYYY-MM-DD
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Asegura que el mes tenga dos dígitos
        const day = String(date.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const fetchAndRenderData = async () => {
            // Formatear las fechas seleccionadas
            const dateString = formatDate(selectedDate);
            
            // Obtener los datos de temperatura
            const temperatureData = await getTemperatureData(fieldId, `${dateString}T00:00:00.000Z`, `${dateString}T23:59:59.999Z`);
            
            // Crear los labels de 0 a 23 (horas)
            const labels = Array.from({ length: 24 }, (_, i) => i);

            // Mapear los valores `mean` a los labels de horas correspondientes
            const dataValues = labels.map(hour => {
                const hourString = `${dateString}T${String(hour).padStart(2, '0')}:00:00.000Z`;
                return temperatureData[hourString] ? temperatureData[hourString].mean : null;
            });

            // Destruir la instancia previa del gráfico si existe
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            // Asegúrate de que el canvas está disponible antes de obtener el contexto
            if (chartRef.current) {
                const ctx = chartRef.current.getContext('2d');

                // Configuración de los datos y opciones del gráfico
                const data = {
                    labels: labels, // Horas de 0 a 23
                    datasets: [
                        {
                            label: `Temperatura`,
                            data: dataValues, // Valores `mean`, algunos podrían ser null
                            borderColor: 'rgba(75, 192, 192, 1)',
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            fill: true,
                            tension: 0.4,
                            spanGaps: true, // Esto permite que las líneas salten los puntos donde no hay datos (null)
                        },
                    ],
                };

                const options = {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: `Gráfico de Temperatura para el Campo ${fieldId}`,
                        },
                    },
                    scales: {
                        x: {
                            title: {
                                display: false,
                                text: 'Horas'
                            }
                        },
                        y: {
                            title: {
                                display: false,
                                text: 'Temperatura (°C)'
                            },
                            min: 0, // Valor mínimo fijo para la escala del eje Y
                            max: 50, // Valor máximo fijo para la escala del eje Y
                            ticks: {
                                stepSize: 5 // Ajusta el tamaño del paso en la escala del eje Y
                            }
                        },
                    },
                };

                // Crear el nuevo gráfico
                chartInstanceRef.current = new Chart(ctx, {
                    type: 'line',
                    data: data,
                    options: options,
                });
            }
        };

        fetchAndRenderData();
    }, [selectedDate, fieldId]); // Escucha cambios en selectedDate y fieldId

    return (
        <div className="chart-container">
            <canvas ref={chartRef}></canvas>
        </div>
    );
};
