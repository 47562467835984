import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/userActions';
import bayer_logo from '../assets/bayer-logo.svg';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../styles/login.css';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, error } = useSelector((state) => state.user);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = () => {
        dispatch(login(email, password, navigate));
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className='login-body-container'>
            <div className="login-logo-container">
                <img src={bayer_logo} alt="Bayer Logo" className="login-logo" />
            </div>
            <div className="login-container">
                <div className="login-logo-container login-logo-nematool">
                    <img src={nematool_logo_RGB} alt="Nematool Logo" className="login-svg_nematool" />
                </div>
                <p className='login-p'>Fill in the data you used to register in Nematool:</p>
                {error && <p style={{ color: 'red' }}><strong>{typeof error === 'string' ? error : JSON.stringify(error)}</strong></p>}
                <form>
                    <div className="login-form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            className='login-input'
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Enter the email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-form-group password-input-container">
                        <label htmlFor="password">Password</label>
                        <input
                            className='login-input'
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Enter the password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span onClick={toggleShowPassword} className="password-toggle-icon">
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </span>
                    </div>
                    <div className='div-login-button'>
                        <button className='login-button' onClick={handleSubmit} disabled={loading}>
                            {loading ? 'Cargando...' : 'Log in'}
                        </button>
                    </div>
                    <div id='div-login-register'>
                        <a id='login-click-here' href="/recoverPassword">I have forgotten my password</a>
                    </div>
                    <div id='div-login-register'>
                        <a>If you have not registered, <a id='login-click-here' href="/register">click here</a></a>
                    </div>
                </form>
                <div className="login-footer">
                    <a href="https://nematool.com/Nematool-UserLicenseAgreement.pdf" id='login-termino'>Terms and conditions</a>
                    <a href="https://nematool.com/Nematool-DataPrivacyStatement.pdf">Privacy policy</a>
                </div>
            </div>
            <div className="login-copy">
                &copy; Bayer CropScience S.L.
            </div>
        </div>
    );
};