import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getField, deleteField, getGuests } from '../actions/fieldsActions';
import { getUser } from '../actions/userActions';
import { postNematode, postSolarization, putNematode, putSolarization } from '../actions/modelActions'; 
import '../styles/field.css';
import { FieldCard } from './FieldCard';
import { getSignalStatus, getModel, getOwner } from '../utils/fieldUtils';
import { Header } from './Header';
import { ModalDelete } from './ModalDelete';
import { ModalShared } from './ModalShared';
import { LineChart } from './LineChart';
import { DateNavigator } from './DateNavigator';
import Map from './Map';

export const Field = () => {
    const { fieldId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [errorMessage, setErrorMessage] = useState('');
    const { fieldData, loading: fieldLoading, error } = useSelector((state) => state.fields);
    const { email: userEmail, loading: userLoading } = useSelector((state) => state.user);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal de eliminación
    const [isSharedUsersModalOpen, setIsSharedUsersModalOpen] = useState(false); // Estado para controlar el modal de usuarios compartidos
    const [fieldToDelete, setFieldToDelete] = useState(null); // Estado para almacenar el campo a eliminar
    const [sharedUsers, setSharedUsers] = useState(); // Estado para almacenar la lista de usuarios compartidos

    useEffect(() => {
        dispatch(getUser());
        if (fieldId) {
            dispatch(getField(fieldId));
        } else {
            console.error("El fieldId es undefined");
        }
    }, [dispatch, fieldId]);

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };
    
    const loading = fieldLoading || userLoading; 

    const f = fieldData;
    let lat, lon;
    if (f && f.area && Array.isArray(f.area.coordinates) && f.area.coordinates.length === 2) {
        lon = f.area.coordinates[0];
        lat = f.area.coordinates[1];
    } else {
        lat = 0;
        lon = 0;
    }
    const ownerEmail = f ? getOwner(f) : '';

    const handleStartNematode = () => {
        if (f) {
            dispatch(postNematode(f.id));
        }
    };

    const handleStartSolarization = () => {
        if (f) {
            dispatch(postSolarization(f.id));
        }
    };

    const handleStopNematode = () => {
        if (f) {
            dispatch(putNematode(f.id, f.active_prediction_model.id));
        }
    };

    const handleStopSolarization = () => {
        if (f) {
            dispatch(putSolarization(f.id, f.active_solarization.id));
        }
    };

    const handleDeleteField = () => {
        setFieldToDelete(f);
        setIsModalOpen(true);
    };

    const confirmDeleteField = async () => {
        if (fieldToDelete) {
            const responseStatus = await deleteField(fieldToDelete.id);
            if (responseStatus !== 200) {
                setErrorMessage("Error, no se pudo eliminar el campo");
            } else {
                navigate('/fields/');
            }
        }
        setIsModalOpen(false); // Cerrar el modal después de la acción
    };

    const cancelDeleteField = () => {
        setIsModalOpen(false); // Cerrar el modal sin eliminar
    };

    const openSharedUsersModal = async () => {
        if (f) {
            try {
                const response = await getGuests(f.id);
                setSharedUsers(response.results);
                setIsSharedUsersModalOpen(true);
            } catch (error) {
                console.log(error)
                setErrorMessage("Error al obtener los usuarios compartidos.");
            }
        }
    };

    const closeSharedUsersModal = () => {
        setIsSharedUsersModalOpen(false);
    };

    const isNematodeActive = f && f.active_prediction_model;
    const isSolarizationActive = f && f.active_solarization;

    const handleMapLoad = (position) => {
        // Puedes realizar otras acciones cuando el mapa se carga
    };

    if (loading) {
        return (
            <div>
                <Header />
                <div className="load-3">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Header />
                <p className='field-error'>Error: {error}</p>
            </div>
        );
    }

    return (
        <div className="field-details">
            <Header />
            {f && (
                <>
                    <div className='sensor-div'>
                        <FieldCard
                            fieldId={f.id}
                            fieldName={f.name} 
                            sensorNumber={f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'No sensor'}   
                            activeSensor={f.sensors.length > 0 && f.sensors[0].is_activated ? 'Active' : 'Desactive'}
                            signalSensor={f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'No Signal'}
                            model={getModel(f)}
                            owner={ownerEmail}
                        />
                    </div>
                    {userEmail === ownerEmail && (
                        <div className='field-div'>
                            <div>
                                {errorMessage && <strong><p className="form-field-message">{errorMessage}</p></strong>}
                            </div>
                            <div className='boton-field-div'>
                                {isNematodeActive && (
                                    <button 
                                        className='field-button'
                                        onClick={handleStopNematode}
                                    >
                                        Stop nematode
                                    </button>
                                )}
                                {isSolarizationActive && (
                                    <button 
                                        className='field-button'
                                        onClick={handleStopSolarization}
                                    >
                                        Stop solarization
                                    </button>
                                )}
                                {!isNematodeActive && !isSolarizationActive && (
                                    <>
                                        <button 
                                            className='field-button' 
                                            onClick={handleStartNematode}
                                        >
                                            Start nematode
                                        </button>
                                        <button 
                                            className='field-button'
                                            onClick={handleStartSolarization}
                                        >
                                            Start solarization
                                        </button>
                                    </>
                                )}
                            </div>
                            <div className='boton-field-div'>
                                <button 
                                    className='field-button' 
                                    onClick={openSharedUsersModal}
                                >
                                    Shared users
                                </button>
                                <button 
                                    className='delete-field-button' 
                                    onClick={handleDeleteField}
                                >
                                    Delete field
                                </button>
                            </div>
                        </div>
                    )}
                    <DateNavigator onDateChange={handleDateChange} />
                    <LineChart selectedDate={selectedDate} fieldId={fieldId} />
                    <Map latitude={lat} longitude={lon} onMapLoad={handleMapLoad} clickable={false} />
                </>
            )}

            {f && userEmail === ownerEmail && (
                <>
                    <ModalDelete 
                        isOpen={isModalOpen} 
                        onClose={cancelDeleteField} 
                        onConfirm={confirmDeleteField}
                        message="¿Estás seguro de que deseas eliminar este campo?"
                    />

                    <ModalShared 
                        isOpen={isSharedUsersModalOpen} 
                        onClose={closeSharedUsersModal} 
                        sharedUsers={sharedUsers}
                        fieldId={f.id}
                        setSharedUsers={setSharedUsers}
                    />
                </>
            )}
                
        </div>
    );
};
