import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import '../styles/settings.css';
import nematool_logo from '../assets/nematool.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, deleteAccount } from '../actions/userActions'

export const Settings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleLogOut = () => {
        dispatch(logout(navigate));
    };

    const handleDeleteAccount = () => {
        dispatch(deleteAccount(navigate));
    };

    const redirectToTerms = () => {
        window.location.href = "https://nematool.com/Nematool-UserLicenseAgreement.pdf";
    };

    const redirectToPolicy = () => {
        window.location.href = "https://nematool.com/Nematool-DataPrivacyStatement.pdf";
    };

    return (
        <div className="settings-div">
            <div className="arrow-container" onClick={handleGoBack}>
                <FontAwesomeIcon className='svg-arrow-left' icon={faArrowLeft} />
            </div>
            <p className='settings-title'>Settings</p>
            <div className="settings-image-div">
                <img 
                    src={nematool_logo}
                    alt="Profile" 
                    className="rounded-image"
                />
            </div>
            <p className='settings-version'>Nematool 2.15.19</p>
            <br />
            <div className='settings-option-div settings-option-div-up' onClick={handleLogOut}>
                <p className='settings-option-p'>Log out</p>
            </div>
            <div className='settings-option-div' onClick={handleDeleteAccount}>
                <p className='settings-option-p'>Delete account</p>
            </div>
            <br />
            <div className='settings-option-div settings-option-div-up' onClick={redirectToTerms}>
                <p className='settings-option-p'>Terms and conditions</p>
            </div>
            <div className='settings-option-div' onClick={redirectToPolicy}>
                <p className='settings-option-p'>Privacy policy</p>
            </div>
        </div>
    );
};
