import React, { useEffect, useState } from 'react';
import '../styles/modalshared.css'; // Asegúrate de que los estilos del modal estén bien configurados
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faShare } from '@fortawesome/free-solid-svg-icons'; 
import { getGuests, deleteGuest, addGuest } from '../actions/fieldsActions';

const handleInsertGuest = async (fieldId, email, sharedUsers, setSharedUsers, setErrorMessage, setEmail) => {
    // Verificar si el correo ya está en la lista
    const isAlreadyShared = sharedUsers.some(user => user.user.email === email);

    if (isAlreadyShared) {
        setErrorMessage('El usuario ya está en la lista.');
        return;
    }

    try {
        const response = await addGuest(fieldId, email);
        if (response === 200) {
            const guestsResponse = await getGuests(fieldId);
            setSharedUsers(guestsResponse.results);
            setErrorMessage('');
            setEmail(''); // Limpiar el valor del input después de una inserción exitosa
        } else if (response === 404) {
            setErrorMessage('The user does not exist.');
        } else {
            setErrorMessage('An error occurred while adding the user.');
        }
    } catch (error) {
        console.error(error);
        setErrorMessage('An error occurred while adding the user.');
    }
};

const handleDeleteGuest = async (fieldId, userId, setSharedUsers) => {
    try {
        const response = await deleteGuest(fieldId, userId);
        if (response.status === 200) {
            setSharedUsers(prevUsers => prevUsers.filter(user => user.user.id !== userId));
        } else {
            console.error('Error deleting user:', response.statusText);
        }
    } catch (error) {
        console.error('Error deleting user:', error);
    }
};

export const ModalShared = ({ isOpen, onClose, sharedUsers, fieldId, setSharedUsers }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState(''); // Estado para manejar el valor del input

    // Limpiar el mensaje de error cada vez que se abre el modal
    useEffect(() => {
        if (isOpen) {
            setErrorMessage(''); // Limpia el mensaje de error cuando se abre el modal
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-shared-content">
                <h2>Shared users</h2>
                {errorMessage && <strong><p className="error-message">{errorMessage}</p></strong>}
                <div className="send-input-container">
                    <input
                        className="modal-shared-input"
                        type="text"
                        id="email"
                        name="email"
                        placeholder="Enter the user email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span 
                        className="modal-shared-toggle-icon" 
                        onClick={() => handleInsertGuest(
                            fieldId, 
                            email, 
                            sharedUsers, 
                            setSharedUsers, 
                            setErrorMessage,
                            setEmail
                        )}
                    >
                        <FontAwesomeIcon icon={faShare} />
                    </span>
                </div>


                {sharedUsers.length > 0 ? (
                    sharedUsers.map((user) => (
                        <div key={user.user.id} className='modal-shared-div-1'>
                            <p className='modal-shared-p'>{user.user.email}</p>
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDeleteGuest(fieldId, user.user.id, setSharedUsers)}
                            />
                        </div>
                    ))
                ) : (
                    <p>No shared users found</p> // Mensaje alternativo si no hay usuarios
                )}
                
                <div className="modal-buttons">
                    <button className="modal-button confirm-button" onClick={onClose}>Go back</button>
                </div>
            </div>
        </div>
    );
};
