import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { rememberPassword } from '../actions/userActions';
import bayer_logo from '../assets/bayer-logo.svg';
import nematool_logo_RGB from '../assets/nematool_logo_RGB.png';
import '../styles/login.css';

export const RecoverPassword = () => {
    const [email, setEmail] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true); // Nuevo estado para manejar la visibilidad del formulario
    const localtoken = localStorage.getItem('authToken');
    const navigate = useNavigate();

    const { loading } = useSelector((state) => state.user);

    useEffect(() => {
        if (localtoken) {
            navigate('/fields');
        }
    }, [localtoken, navigate]);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setPasswordError('');

        if (email === '') {
            setPasswordError('Complete the email.');
            return;
        }

        const response = await rememberPassword(email);
        if (typeof response === 'string') {
            setPasswordError(response);
        } else {
            setIsFormVisible(false);
        }
    };

    const handleGoToLogin = () => {
        navigate('/login');
    };

    return (
        <div className='login-body-container'>
            <div className="login-logo-container">
                <img src={bayer_logo} alt="Bayer Logo" className="login-logo" />
            </div>
            <div className="login-container">
                <div className="login-logo-container login-logo-nematool">
                    <img src={nematool_logo_RGB} alt="Nematool Logo" className="login-svg_nematool" />
                </div>
                {passwordError && <p style={{ color: 'red' }}><strong>{passwordError}</strong></p>}
                {isFormVisible ? ( 
                    <form onSubmit={handleSubmit}>
                        <div className="login-form-group">
                            <label htmlFor="email">Enter the email with which you registered:</label>
                            <input
                                className='login-input'
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className='div-login-button'>
                            <button className='login-button' type="submit" disabled={loading}>
                                {loading ? 'Cargando...' : 'Remember my password'}
                            </button>
                        </div>
                        <div id='div-login-register'>
                            <a>Do you already have an account? <a id='login-click-here' href="/login">Log in</a></a>
                        </div>
                    </form>
                ) : (
                    <div>
                        <p style={{ color: 'green' }}><strong>Check your email inbox.</strong></p>
                        <div className='div-login-button'>
                            <button className='login-button' disabled={loading} onClick={handleGoToLogin}>
                                {loading ? 'Cargando...' : 'Go to login'}
                            </button>
                        </div>
                    </div>
                )}
                <div className="login-footer">
                    <a href="https://nematool.com/Nematool-UserLicenseAgreement.pdf" id='login-termino'>Terms and conditions</a>
                    <a href="https://nematool.com/Nematool-DataPrivacyStatement.pdf">Privacy policy</a>
                </div>
            </div>
            <div className="login-copy">
                &copy; Bayer CropScience S.L.
            </div>
        </div>
    );
};
